<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">
      Costos Mano de Obra
    </h2>
    <button
      v-if="getWorkCategories && !getWorkCategories.length"
      class="
        rounded
        border border-primary
        bg-primary
        py-2
        px-4
        text-white
        font-medium
        flex
      "
      @click="onEnable"
    >
      Habilitar costos
    </button>
  </div>

  <div class="grid relative w-full lg:px-10 px-5">
    <div class="overflow-x-scroll">
      <table class="w-full">
        <thead class="">
          <tr>
            <th class="px-6 py-2 text-sm text-gray-500 text-left pl-0">
              Categoría
            </th>
            <th class="px-6 py-2 text-sm text-gray-500 text-left pl-0">
              Costo unitario (HH)
            </th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <tr
            v-for="(workCategory, categoryInd) in getWorkCategories"
            :key="categoryInd"
            class="whitespace-nowrap"
          >
            <td
              class="text-left text-secondary font-light px-6 py-4 text-sm pl-0"
            >
              {{ workCategory.categoryName }}
            </td>
            <td
              class="text-left text-secondary font-light px-6 py-4 text-sm pl-0"
            >
              {{ workCategory.unitCost }}
            </td>
            <td class="px-6 py-4 pr-0">
              <div class="flex md:justify-end">
                <a
                  class="
                    text-primary
                    font-medium
                    cursor-pointer
                    text-right
                    hover:bg-blue-50
                    px-2
                  "
                  @click="
                    onOpenDrawerCategory({
                      action: 'update',
                      category: workCategory,
                    })
                  "
                >
                  <p>Editar</p>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <EndDrawer :open="drawerCategory" @update:open="drawerCategory = $event">
    <CategoryForm
      v-if="drawerCategory"
      :action="categoryFormAction"
      :category-selected="categorySelected"
      @onComplete="drawerCategory = false"
    />
  </EndDrawer>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import CategoryForm from "./forms/Category";

const store = useStore();

const drawerCategory = ref(false);
const categoryFormAction = ref("update");
const categorySelected = ref(null);

const getWorkCategories = computed(() => store.getters.getWorkCategories);

const onOpenDrawerCategory = ({ action, category = null }) => {
  categorySelected.value = { ...category };
  drawerCategory.value = true;
  categoryFormAction.value = action;
};

const init = async () => {
  await store.dispatch("getAllWorkCategories");
};

init();

const onEnable = async () => {
  try {
    await store.dispatch("enableWorkCategories");
  } catch (error) {
    console.error(error);
  }
};
</script>
